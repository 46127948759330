<template>
  <div class="dropdown">
    <div class="dropdown-list" v-for="(dropdown, index) in dropdownItems" :key="index">
        <!-- :style="displayMonthly === dropdown.title ? titleBackground:''" -->
      <p
        @click="actClicked(dropdown.title)"
      >{{dropdown.title}}</p>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main";

export default {
  props: ["dropdownItems", "content"],
  methods: {
    actClicked(data) {
        let _this = this;
      let param = {};
      param.plantype = data;
      param.content = this.content;
      EventBus.$emit("selectedDropdown", param);
    }
  }
};
</script>

<style lang="scss" scoped >
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.dropdown {
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 4px;
  width: 40%;
  border: 1px solid $clr-bg-gray-light-7;
  color: $font-clr-white;
  background: $clr-bg-black-1 0% 0% no-repeat padding-box;
  z-index:3;
  .dropdown-list{
    min-height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $font-clr-gray-1; 
    &:hover{
          background: $clr-bg-gray-dark 0% 0% no-repeat padding-box;
          color: $font-clr-gray; 
    }
  }
}
</style>